.mdc-radio__outer-circle {
  border-width: 1.25px;
}

.mat-mdc-radio-button
.mdc-radio
.mdc-radio__native-control:enabled:not(:checked)
+ .mdc-radio__background
.mdc-radio__outer-circle {
  border-color: $bordercheckboxColor;
}

.mat-mdc-radio-button
.mdc-radio
.mdc-radio__native-control:enabled:checked
+ .mdc-radio__background
.mdc-radio__outer-circle {
  border-width: 10px;
}

.mat-mdc-radio-button
.mdc-radio
.mdc-radio__native-control:enabled
+ .mdc-radio__background
.mdc-radio__inner-circle {
  border-color: $white;
}

.mat-mdc-radio-button .mdc-radio:hover .mdc-radio__native-control:not([disabled]):not(:focus)~.mdc-radio__background::before {
  opacity: 1;
}

