.mat-mdc-menu-item:hover:not([disabled]) {
  background: $hoverBgColor;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.w-80 {
  width: 80%;
}

.text-hover-primary:hover {
  .hover-text {
    color: $primary;
  }
}

.z-1 {
  z-index: 1;
}

.w-100 {
  width: 100%;
}

.w-max {
  width: max-content;
}

.h-100 {
  height: 100%;
}

.mt-auto {
  margin-top: auto;
}

.shadow-none {
  box-shadow: none !important;
}

.shadow-none {
  box-shadow: none!important
}

.shadow-1 {
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
}

.shadow-2 {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.shadow-3 {
  box-shadow: 0 1px 8px #00000014,0 3px 4px #0000001a,0 1px 4px -1px #0000001a!important
}

.shadow-4 {
  box-shadow: 0 1px 10px #0000001f,0 4px 5px #00000024,0 2px 4px -1px #0003!important
}

.shadow-5 {
  box-shadow: 0 1px 7px #0000001a,0 4px 5px -2px #0000001f,0 10px 15px -5px #0003!important
}

.shadow-6 {
  box-shadow: 0 3px 5px #0000000f,0 7px 9px #0000001f,0 20px 25px -8px #0000002e!important
}

.shadow-7 {
  box-shadow: 0 7px 30px #00000014,0 22px 30px 2px #00000026,0 8px 10px #00000026!important
}

.shadow-8 {
  box-shadow: 0 9px 46px 8px #0000001f,0 24px 38px 3px #00000024,0 11px 15px #0003!important
}

.overflow-hidden {
  overflow: hidden;
}

.text-decoration-none {
  text-decoration: none;
}

.position-relative {
  position: relative;
}

.op-5 {
  opacity: 0.5;
}

.op-75 {
  opacity: 0.75;
}

.cursor-pointer {
  cursor: pointer;
}
