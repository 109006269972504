.rounded-sm {
  border-radius: 6px !important;
}

.rounded {
  border-radius: $border-radius !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 25px !important;
}
