@use "sass:meta";

@mixin syntax-colors($args...) {
  @each $name, $color in meta.keywords($args) {
    .bg-#{$name} {
      background-color: $color !important;
    }

    .text-#{$name} {
      color: $color !important;
    }
    .color-#{$name} {
      color: $color !important;
    }
  }
}

@function color-scale($base-color, $type, $percentage) {
  @if $type == 'light' {
    @return mix($white, $base-color, $percentage);
  } @else if $type == 'dark' {
    @return mix($black, $base-color, $percentage);
  }
}

@include syntax-colors(
  $primary: $primary,
  $primary-100: color-scale($primary, 'light', 80%),
  $primary-200: color-scale($primary, 'light', 60%),
  $primary-300: color-scale($primary, 'light', 40%),
  $primary-400: color-scale($primary, 'light', 20%),
  $primary-500: $primary,
  $primary-600: color-scale($primary, 'dark', 20%),
  $primary-700: color-scale($primary, 'dark', 40%),
  $primary-800: color-scale($primary, 'dark', 60%),
  $accent: $accent,
  $light-base: $light-base,
  $base: $base,
  $success: $success,
  $warning: $warning,
  $error: $error,
  $white: $white,
  $light: $light,
  $muted: $muted,
  $dark: $dark,
  $dark-200: $dark-200,
  $gray-100: $gray-100,
  $light-error: $light-error,
  $light-accent: $light-accent,
  $light-success: $light-success,
  $light-warning: $light-warning,
  $light-primary: $light-primary
);

.fill-warning svg {
  fill: $warning;
  color: $warning;
}

.fill-light svg {
  fill: $light;
  color: $light;
}

.primary {
  color: $primary;
}
