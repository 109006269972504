@use "sass:meta";

$columns: 48;

@mixin icon_size {
  @for $i from 1 through $columns {
    .icon-#{$i} {
      height: #{$i}px !important;
      width: #{$i}px !important;
    }
  }
}

@mixin MatIconButtonSize($name, $buttonSize, $iconSize) {
.mat-#{$name}-icon-button {
  width: $buttonSize !important;
  height: $buttonSize !important;
  padding: 0px !important;
  display: inline-flex !important;
  align-items: center;
  justify-content: center;

  & > *[role=img] {
    width: $iconSize;
    height: $iconSize;
    font-size: $iconSize;

    svg {
      width: $iconSize;
      height: $iconSize;
    }
  }

  .mat-mdc-button-touch-target {
    width: $buttonSize !important;
    height: $buttonSize !important;
  }
}
}

@include MatIconButtonSize('large', 48px, 32px);
@include MatIconButtonSize('medium', 32px, 19px);
@include MatIconButtonSize('small', 26px, 16px);
@include MatIconButtonSize('tiny', 20px, 12px);


@include icon_size;


i-tabler {
  stroke-width: 1.5px !important;
}
