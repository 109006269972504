@media screen and (max-width: 419px){
  .mat-horizontal-stepper-header-container
  {
    overflow-x: auto;
  }
  .mat-horizontal-stepper-header
  {
    min-width: fit-content;
  }
  .mat-horizontal-content-container {
    padding: 0 8px 8px 8px !important;
  }
}
