*,
:after,
:before {
  box-sizing: border-box;
}

body {
  font-family: $font-family;
  color: $bodyColor;
  font-size: 14px;
  line-height: normal;
  overflow-x: hidden;
  margin: 0;
}

html .mat-drawer-container {
  background-color: $white;
}

.mainWrapper {
  display: flex;
  min-height: 100vh;
  width: 100%;
  flex: 1;
  height: 100%;
}

.body-wrapper {
  border-radius: 20px;
  background: $body-bg;
  margin: 0 15px 30px 0;
  min-height: calc(100% - 102px);
}

@media (max-width: 1023px) {
  .body-wrapper {
    margin-right: 0;
  }
}

.pageWrapper {
  padding: 30px 2px;
  min-height: calc(100vh - 100px);

  margin: 0 auto;
  &.maxWidth {
    max-width: $boxedWidth;
  }
}

.outlet-height {
  min-height:$outletHeight;
}

.container {
  max-width: 1200px;
  padding-left: 30px;
  padding-right: 30px;
  margin: 0 auto;
  &.full-width {
    display: flex;
    align-items: center;
    width: 100%;
  }
}

.hstack {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}
