@mixin generate-height-classes($min: 1, $max: 100, $unit: px) {
  @for $i from $min through $max {
    .height-#{$i} {
      height: #{$i}#{$unit} !important;
    }
  }
}

@mixin generate-width-classes($min: 1, $max: 100, $unit: px) {
  @for $i from $min through $max {
    .width-#{$i} {
      width: #{$i}#{$unit} !important;
    }
  }
}

@include generate-height-classes(10, 200, px);

@include generate-width-classes(10, 200, px);
