.view_icons_container {
  @extend .m-x-6
}

@media (min-width: 300px) and (max-width: 820px) {
  advanced-search-component {
    position: absolute;
    z-index: 99999;

  [dir="ltr"] & {
      right: 10px;
      left: auto;
  }
  [dir="rtl"] & {
    left: 10px;
    right: auto;
  }
  }
}

@media (min-width: 300px) and (max-width: 820px) {
  .view_icons_container ,.toolbar-icon {
    position: absolute;
    top: 10px;
    [dir="ltr"] & {
      right: 50px;
      left: auto;
    }
    [dir="rtl"] & {
      left: 50px;
      right: auto;
    }
  }
}

@media (min-width: 300px) and (max-width: 820px) {
  .toolbar-icon {
    position: absolute;
    top: 0;
    [dir="ltr"] & {
      right: 40px;
      left: auto;
    }
    [dir="rtl"] & {
      left: 40px;
      right: auto;
    }
  }
}

@media (min-width: 300px) and (max-width: 820px) {
  .barcode_icon {
    position: absolute;
    left: 50px;
    top: 15px;
  }
  [dir="ltr"] {
    .barcode_icon {
      right: 50px;
      left: auto;
      top: 15px;
    }
  }
}

//.block-search {
//  align-self: baseline;
//  margin-top: 12px;
//  advanced-search-component {
//    position: relative !important;
//  }
//}
advanced-search-component {
  align-self: baseline;
  padding: 10px 0;
}
