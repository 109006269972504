.mat-mdc-menu-panel,
.mat-expansion-panel {
  box-shadow: $mainshadow !important;
  margin-bottom: 16px !important;

  .mat-expansion-panel-header-title {
    @extend  .f-w-600;
    @extend  .f-s-16;
    flex-basis: auto;
  }
  .mat-expansion-panel-body {
    padding: 0 12px 16px;
  }
}
