$bordertableColor: #ccc;

.mdc-data-table__cell,
.mdc-data-table__header-cell,
.mat-mdc-cell {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  border-bottom-color: $bordertableColor !important;
  word-break: break-all;
}

.mat-mdc-header-cell {
  @extend .f-w-700 !optional;
}
.mat-column-actions, .mat-column-buttons {
  width: 55px;
  max-width: 65px;
}

.table-responsive {
  overflow-x: auto;

  td,
  mat-cell {
    white-space: nowrap;
  }
}

.timesheet-table {
  overflow-x: auto;
  .mat-mdc-header-cell, .mat-mdc-footer-cell, .mat-mdc-cell {
    min-width: 140px;
    box-sizing: border-box;
    justify-content: center;
    text-align: center;
    word-wrap: break-word;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .mat-mdc-header-row, .mat-mdc-footer-row, .mat-mdc-row {
    min-width: 1920px;
  }

  .mat-mdc-table-sticky {
    z-index: 2;
    background: white;
  }
}

.table-xs-responsive {
  overflow-x: auto;

  .mdc-data-table__cell,
  .mdc-data-table__header-cell,
  .mat-mdc-cell {
    min-width: 220px;
  }

  mat-cell.mat-column-actions, mat-cell.mat-column-buttons, mat-header-cell.mat-column-actions, mat-header-cell.mat-column-buttons, mat-footer-cell.mat-column-actions, mat-footer-cell.mat-column-buttons {
    max-width: 65px !important;
    min-width: 50px !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.layered-table {
  .mat-mdc-header-cell {
    @extend .bg-light-base !optional;
    @extend .color-base !optional;
    @extend .f-w-700 !optional;
  }
}

.mat-mdc-paginator-range-label {
  margin: 0 12px;
}
