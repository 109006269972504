//.light-theme {
//  @import "./buttons";
//  @import "./drawer";
//  @import "./card";
//  @import "./typography";
//  @import "./menu";
//  @import "./checkbox";
//  @import "./radio";
//
//  .topbar {
//    background-color: $toolbar;
//  }
//}
//
//body {
//  @import "./form";
//}

//.crystal_nexus_theme {
//  --mat-theme-primary-color: #87CEEB;
//  --mat-theme-accent-color: #FF9999;
//}

.light-theme,
.dark-theme {
  @import "./buttons";
  @import "./drawer";
  @import "./card";
  @import "./typography";
  @import "./menu";
  @import "./checkbox";
  @import "./radio";

  .topbar {
    background-color: $toolbar;
  }
}

body {
  @import "./form";
}

@import "./borderColor";
@import "./table";
@import "./rounded";

@import "./list";
@import "./tab";
@import "./expansion";
@import "./stepper";

