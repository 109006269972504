.welcome-bg {
  margin-bottom: -34px;
}

.labels-chart {
  .label-1 {
    position: absolute;
    width: 20px;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: -3px;
  }

  .label-2 {
    position: absolute;
    right: -15px;
    top: 50%;
  }

  .label-3 {
    position: absolute;
    width: 40px;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: 0;
    text-align: center;
  }

  .label-4 {
    position: absolute;
    left: -15px;
    top: 50%;
  }
}

@media (min-width: 1500px) {
  .labels-chart {
    .label-2 {
      right: 0;
    }
    .label-4 {
      left: 0;
    }
  }
}

// theme select
.theme-select {
  width: 125px;
  height: 40px;
  .mat-form-field-infix {
    padding: 6px 0 1px !important;
  }
  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }
  .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading {
    border-top-left-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
    border-color: $borderColor;
  }
  .mdc-text-field--outlined
  .mdc-notched-outline
  .mdc-notched-outline__trailing {
    border-top-right-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
    border-color: $borderColor;
  }
  .mat-mdc-form-field-flex {
    height: 40px;
    align-items: center;
  }
}

.theme-tab {
  &.label-padding {
    .mat-mdc-tab-labels {
      padding: 30px 30px 15px;
    }
  }
  .mat-mdc-tab-labels {
    gap: 16px;
  }
  .mat-mdc-tab:not(.mdc-tab--stacked) {
    height: 45px;
  }
  .mdc-tab-indicator {
    display: none;
  }
  .mdc-tab {
    border-radius: $border-radius;
    overflow: hidden;
    background: $gray-200;
    .mdc-tab__text-label {
      color: $textPrimary;
      font-weight: 600;
    }
    &.mdc-tab--active.mdc-tab-indicator--active {
      background: $primary;

      .mdc-tab__text-label {
        color: white !important;
      }
    }
  }
}

@media (max-width: 1023px) {
  .welcome-bg-top {
    margin-top: 16px;
    text-align: center;
  }
}

.badge-custom-dark {
  background-color: rgba(41, 52, 61, 0.2);
  padding: 7px 12px;
}

.bg-primary-gt {
  position: relative;
  &:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    background: url(/assets/images/backgrounds/welcome-bg2.png);
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: right bottom;
  }
}

.rounded-bars
.apexcharts-bar-series.apexcharts-plot-series
.apexcharts-series
path {
  clip-path: inset(0 0 5% 0 round 20px);
}
.rounded-pill-bars .apexcharts-rangebar-area {
  clip-path: inset(9% 0 11% round 24px);
}

.timeline {
  position: relative;
  .timeline-item {
    position: relative;
    height: 59px;
    &:last-child {
      // height: 30px;
      .point {
        .timeline-border {
          display: none !important;
        }
      }
    }
    .time {
      padding: 6px 16px 6px 0;
      min-width: 90px;
      flex-shrink: 0;
    }
    .desc {
      padding: 3px 16px;
    }
    .timline-border {
      width: 1px;
      height: 100%;
      background-color: $borderColor;
      flex-shrink: 0;
    }
    .point {
      flex-direction: column;
      .timeline-badge {
        width: 12px;
        height: 12px;
        border-radius: 50px;
        background-color: transparent;
        flex-shrink: 0;
      }

      .timeline-border {
        width: 1px;
        height: 100%;
        background-color: $borderColor;
        flex-shrink: 0;
      }
    }
  }
}

.social-chips {
  img,
  span {
    margin-left: -20px;
    border: 2px solid $white;
    &:first-child {
      margin-left: 0;
    }
  }
}

.lh-base {
  line-height: 25px !important;
}

.warning-gt {
  background: linear-gradient(
      180deg,
      rgba($warning, 0.12) 0,
      rgba($warning, 0.03) 100%
  );
}

.error-gt {
  background: linear-gradient(
      180deg,
      rgba($error, 0.12) 0,
      rgba($error, 0.03) 100%
  );
}

.success-gt {
  background: linear-gradient(
      180deg,
      rgba($success, 0.12) 0,
      rgba($success, 0.03) 100%
  );
}

.btn-shadow {
  box-shadow: 0 6px 24.2px -10px rgba(41, 52, 61, 0.22);
}
