
mat-label {
  color: $dark-200;
}

.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field
.mdc-notched-outline__notch {
  border-left: 0 !important;
}

.mdc-form-field > label {
  font-size: 14px;
}

.hide-hint {
  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }
}

.theme-expansion {
  .mat-expansion-panel-header {
    padding: 20px 32px;
    height: 62px;
  }
}

input[readonly] {
  pointer-events: none;
  cursor: not-allowed;
  color: $gray-500 !important;
}
